import Service from '@/helpers/service'

export default {

    get(id, params) {
        return Service().get('sap-configuration/sap-sync-param-mapping/'+id, params);
    },
    show(id) {
        return Service().get('sap-configuration/sap-sync-param-mapping/show/'+id);
    },    
    create(params) {
        return Service().post('sap-configuration/sap-sync-param-mapping/create', params);
    },
    update(id, params) {
        return Service().post('sap-configuration/sap-sync-param-mapping/update/'+id, params);
    },
    delete(id) {
        return Service().post('sap-configuration/sap-sync-param-mapping/delete/'+id);
    }
}